$main_blue: #0ae7f8;

.button-light {
  background: rgba(10, 232, 248, 0.2);
  border: 1px solid $main_blue;
  border-radius: 5px;
  font-family: "Monda Regular";
  font-size: 16px;
  line-height: 26px;
  padding: 5px 20px;
  color: #ffffff;
  cursor: pointer;
}

.button-disabled {
  background: rgba(97, 97, 97, 0.55);
  border: 1px solid rgba(255, 255, 255, 0.55);
  border-radius: 5px;
  font-family: "Monda Regular";
  font-size: 16px;
  line-height: 26px;
  padding: 5px 20px;
  color: #ffffff;
}

.button-dark {
  background: rgba(0, 0, 0, 0.2);
  border: 1px solid $main_blue;
  border-radius: 5px;
  font-family: "Monda Regular";
  font-size: 16px;
  line-height: 26px;
  padding: 11px 20px;
  color: #ffffff;
  cursor: pointer;
}

.button-connector {
  min-width: 250px;
}
