$main_blue: #0ae7f8;
$second_blue: #1b31dd;

p {
  font-size: 13px;
  margin: 5px;
}

.stats-link {
  color: #ffffff;
  text-decoration: none;
  width: 100%;
}
.pool-box {
  border: 1px solid $main_blue;
  border-radius: 5px;
  font-family: "Gotham Medium";
  // padding: 15px 20px;
  padding: 10px 0 10px 15px;
  color: #ffffff;
  background-color: #000000;
}

.pair-name {
  margin-bottom: -10px;
}

.pinned-pool {
  background-color: #002e32;

  .badge {
    background: #17858e;
    color: white;
  }

  .pool-box-button {
    line-height: 35px;
    background-color: #00f2ff;
    color: #000000;
    border: #00f2ff;
  }
}

.token-image {
  width: 34px;
  border-radius: 50%;
}

.token-image-small {
  width: 20px;
  border-radius: 50%;
}
.token-image-unsecure {
  cursor: help;
}

.cex-name {
  font-size: 10pt;
  margin-bottom: 0;
}

.tag {
  text-align: center;
  border-radius: 5px;
  font-size: 7pt;
  padding: 5px 0 0 0;
  margin-left: -15px;
}
.tag-light {
  background: #022e32;
}

.tag-dark {
  background-color: #1e1e1e;
}

.separator-container {
  width: 2px;
  display: flex;
  align-items: center;
}

.separator {
  margin: auto;
  border: 0;
  border-left: 1px solid #0ae7f8;
  height: 45px;
  width: 0px;
}

.badge {
  font-family: "Gotham Book";
  font-style: normal;
  font-weight: 325;
  font-size: 12px;
  line-height: 14px;
  margin: 0;
  padding: 0.7rem 0.7rem 0.2rem 0.7rem;
  display: inline-block;
  border-radius: 5px;
  background: #1e1e1e;

  &.big_badge {
    font-size: 18px;
  }
}

.part3 {
  width: 20%;
}

.pool-box-button {
  height: 35px;
  line-height: 32px;
  padding: 4px 15px;
  font-size: 10pt;
  border-radius: 5px;
  background-color: $main_blue;
  color: #000000;
  border: 1px solid $main_blue;

  &.button-100 {
    width: 100px;
  }
}

.pl-5 {
  padding-left: 30px;
}

.full-center {
  align-items: center;
  display: flex !important;
  height: 100%;
  justify-content: center;
}

@media (max-width: 1201px) {
  .part3 {
    width: 95%;
  }

  .tag {
    margin-left: 1px;
  }
}
