.token-symbol-link {
  text-decoration: none;
  color: #a1a1a1;
}

.pair-name {
  .token-symbol-link {
    text-decoration: none;
    color: #ffffff;
  }
}

.token-symbol-link.active,
.token-symbol-link:hover {
  color: #ffffff;
}
