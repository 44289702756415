hr {
  color: rgba(10, 232, 248, 0.5);
  width: 100%;
}

.rewards-title {
  font-family: "Gotham Medium" !important;
  font-size: 18px !important;
}

.token-symbol-txt {
  font-size: 14px;
}

.end-date-title {
  font-family: "Gotham Book";
  font-size: 15px;
}

.end-date {
  font-family: "Gotham Book";
  font-size: 14px;
}
