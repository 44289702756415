$main_blue: #0ae7f8;

.selector {
  border-radius: 5px;
  background-color:#000;
}
.selector__placeholder{
  color: #fff !important;
  font-family: "Gotham Book";
  font-size: 10pt;
}

.selector__control.css-1s2u09g-control, 
.selector__control.selector__control--is-focused {
  height: 42px !important;
  padding: 5px 10px 0 !important;
  border-radius: 5px;
  background-color:#000;
} 

.selector__input{
  color: #fff !important;
  font-family: "Gotham Book";
  font-size: 10pt;
  margin-left: 5px !important;
}

.selector__single-value {
  color: white !important;
}
  
.selector__option{
  text-align:left;
  color: #fff !important;
  font-family: "Gotham Book";
  font-size: 14pt;
}