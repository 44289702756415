$main_blue: #0ae7f8;

.select-box {
  position: relative;
  display: block;
  width: 100%;
  margin: 0 auto;
  font-family: "Gotham Book";
  font-size: 18px;
  color: #ffffff;
  border: 1px solid $main_blue;
  border-radius: 5px;
}

.select-box__current {
  position: relative;
  box-shadow: 0 15px 30px -10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  outline: none;
}

.select-box__current:focus + .select-box__list {
  opacity: 1;
  -webkit-animation-name: none;
  animation-name: none;
}

.select-box__current:focus + .select-box__list .select-box__option {
  cursor: pointer;
}

.select-box__current:focus .select-box__icon {
  -webkit-transform: translateY(-50%) rotate(180deg);
  transform: translateY(-50%) rotate(180deg);
}

.select-box__icon {
  position: absolute;
  top: 50%;
  right: 15px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 10px;
  -webkit-transition: 0.2s ease;
  transition: 0.2s ease;
}

.select-box__value {
  display: -webkit-box;
  display: flex;
  background: black;
  border-radius: 5px;
}
.select-box__input {
  display: none;
}
.select-box__input:checked + .select-box__input-text {
  display: block;
}
.select-box__input-text {
  display: none;
  width: 100%;
  font-size: 10pt;
  padding: 1rem 0.5rem 0.5rem 0.5rem;
  background-color: #000000;
  border-radius: 5px;
}

.select-box__list {
  z-index: 1;
  background-color: #000000;
  margin-top: 20px;
  border-radius: 5px;
  border: 1px solid $main_blue;
  position: absolute;
  width: 100%;
  padding: 0;
  list-style: none;
  opacity: 0;
  -webkit-animation-name: HideList;
  animation-name: HideList;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: step-start;
  animation-timing-function: step-start;
  box-shadow: 0 15px 30px -10px rgba(0, 0, 0, 0.1);
}

.select-box__list::before {
  content: " ";
  position: absolute;
  background: linear-gradient(-45deg, #000000, #000000 51%, $main_blue 49%);
  width: 15px;
  height: 15px;
  top: -7px;
  left: 13%;
  margin-left: -15px;
  transform: rotate(45deg);
  z-index: -3;
}

.select-box__option {
  display: block;
  font-size: 12pt;
  padding: 15px 15px 5px 15px;
  background-color: #000000;
  border-radius: 5px;
  z-index: 2;
}

.select-box__option:hover,
.select-box__option:focus {
  background-color: #0ae7f84d;
  z-index: 2;
}

@-webkit-keyframes HideList {
  from {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
  to {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
  }
}

@keyframes HideList {
  from {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
  to {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
  }
}
