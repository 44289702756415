$main_blue: #0ae7f8;
$second_blue: #1b31dd;

.red-button {
  border: 1px solid red;
  border-radius: 5px;
  font-family: "Gotham Medium";
  color: #ffffff;
  padding: 10px 20px 0 20px;
  background-color: #171717;
}

.red-button:hover {
  border: 1px solid red;
  border-radius: 5px;
  font-family: "Gotham Medium";
  color: #ffffff;
  padding: 10px 20px 0 20px;
  background: rgba(248, 10, 10, 0.2);
}

.blue-dark-button {
  border: 1px solid $second_blue;
  border-radius: 5px;
  font-family: "Gotham Medium";
  color: #ffffff;
  padding: 10px 20px 0 20px;
  background-color: #171717;
}

.blue-dark-button:hover {
  border: 1px solid $second_blue;
  border-radius: 5px;
  font-family: "Gotham Medium";
  color: #ffffff;
  padding: 10px 20px 0 20px;
  background: rgba(0, 4, 255, 0.2);
}

.data-box-title {
  color: #646464;
}

.scroll-box {
  height: 250px;
  overflow: auto;
}

td,
th {
  padding-left: 30px;
  text-align:center;
}

td {
  padding-top: 20px;
  font-family: "Gotham Book";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 12px;
  text-align:center;
}

@media (max-width: 576px) {
  .certify-button {
    justify-content: center !important;
  }
  .stop-certify {
    margin-top: 1rem;
  }
}
