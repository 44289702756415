footer {
  .logo-container {
    padding: 0 10px;

    .social-logo {
      width: 40px;
      height: 40px;
      background-color: #fff;
      border-radius: 50%;
      justify-content: center;
      align-items: center;
      display: flex;
    }

    .image-discord {
      width: 25px;
    }

    .image-twitter {
      width: 25px;
    }

    .image-telegram {
      width: 20px;
    }
  }
}
