$main_blue: #0ae7f8;

.chart-title {
  font-family: "Gotham Book";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  margin-bottom: 7px;
  color: #666666;
}

.chart-selector {
  padding: 15px 20px 5px 20px;
  border-radius: 5px;
  background-color: #000000;
  color: #ffffff;
  border: 1px solid #ffffff;
  font-family: "Gotham Book";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 16px;
  width: 100%;
}

.epoch-tag {
  margin: 0;
  font-size: 11pt;
  color: $main_blue;
}

.selected {
  background: rgba(10, 232, 248, 0.1);
  border: 1px solid $main_blue;
}

@media (max-width: 436px) {
  .chart-selector {
    padding-left: 20px;
  }
}

@media (max-width: 420px) {
  .chart-selector {
    padding-left: 15px;
  }
}

@media (max-width: 400px) {
  .chart-selector {
    padding-left: 10px;
  }
}

@media (max-width: 360px) {
  .chart-selector {
    padding-left: 5px;
  }
}
