$main_blue: #0ae7f8;
$second_blue: #1b31dd;

.blue-dark-button {
  border: 1px solid $second_blue;
  border-radius: 5px;
  font-family: "Gotham Medium";
  color: #ffffff;
  padding: 10px 20px 0 20px;
  background-color: #171717;
}

.blue-dark-button:hover {
  border: 1px solid $second_blue;
  border-radius: 5px;
  font-family: "Gotham Medium";
  color: #ffffff;
  padding: 10px 20px 0 20px;
  background: rgba(0, 4, 255, 0.2);
}
