@media (max-width: 577px){
    .private-key-btn{
        margin-top: 20px;
    }
}
.messages p{
    text-align: center;
    font-size: 13px;
    font-weight: bold;
    color: red;
}